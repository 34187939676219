








































import Vue from 'vue';
import { LGeoJson } from 'vue2-leaflet';

import FeaturesMarker from '@/components/FeaturesMarker.vue';
import GeoJsonLayerLegend from '@/components/GeoJsonLayerLegend.vue';
import CoordinateGrid from '@/components/CoordinateGrid.vue';
import { LControl } from 'vue2-leaflet';

import {
  GeoJsonLayer,
  GeoJsonLayerKey,
  geoJsonLayers,
} from '@/data/geoJsonLayers';

export default Vue.extend({
  methods: {
    getLayerStyle(feature: any, layer: GeoJsonLayer) {
      if (feature.properties.style) {
        return feature.properties.style;
      } else {
        return {
          weight: 1.5,
          color: layer.color,
          stroke: true,
          opacity: 0.7,
          fillOpacity: 0.08,
        };
      }
    },
  },
  components: {
    CoordinateGrid,
    LGeoJson,
    FeaturesMarker,
    GeoJsonLayerLegend,
    LControl,
  },
  computed: {
    selectedGeoJsonLayers(): typeof geoJsonLayers {
      return geoJsonLayers.filter(({ key }) =>
        this.$accessor.map.selectedGeoJsonLayerKeys.includes(
          key as GeoJsonLayerKey
        )
      );
    },
    pointToLayerFunction(): () => void {
      return () => undefined;
    },
    showCoordinateGrid() {
      return this.$accessor.map.selectedGeoJsonLayerKeys.includes(
        GeoJsonLayerKey['Coordinate Grid']
      );
    },
  },
});
