














import Vue, { PropOptions } from 'vue';
import * as L from 'leaflet';

import {
  controlComponents,
  ControlComponentName,
} from '@/lib/controlComponents';

export default Vue.extend({
  components: controlComponents,
  props: {
    leafletMap: {
      type: Object,
      required: true,
    } as PropOptions<L.Map>,
  },
  computed: {
    selectedControlComponentName(): ControlComponentName | undefined {
      return this.$accessor.ui.selectedControlComponent;
    },
  },
  watch: {
    selectedControlComponentName(componentName: ControlComponentName): void {
      if (componentName === undefined) this.enableMouseGestures();
    },
  },
  methods: {
    enableMouseGestures(): void {
      this.leafletMap.dragging.enable();
      this.leafletMap.scrollWheelZoom.enable();
    },
    disableMouseGestures(): void {
      this.leafletMap.dragging.disable();
      this.leafletMap.scrollWheelZoom.disable();
    },
  },
});
