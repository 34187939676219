















import Vue from 'vue';

import PositionsMarker from '@/components/PositionsMarker.vue';

export default Vue.extend({
  components: {
    PositionsMarker,
  },
});
