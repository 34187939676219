import sarZones from '@/assets/sarZones.json';
import contestedSarZones from '@/assets/contestedSarZones.json';
import gsmCoverage from '@/assets/gsmCoverage.json';
import oilAndGasPlatforms from '@/assets/oilAndGasPlatforms.json';
import territorialWaters from '@/assets/territorialWaters.json';

export enum GeoJsonLayerKey {
  'GSM Coverage' = 'GSM Coverage',
  'Territorial Waters' = 'Territorial Waters',
  'SAR Zones' = 'SAR Zones',
  'Contested SAR Zones' = 'Contested SAR Zones',
  'Oil and Gas Platforms' = 'Oil and Gas Platforms',
  'Coordinate Grid' = 'Coordinate Grid',
}

export type GeoJsonLayerLegend = {
  description: string;
  style: string;
  groupByProperty?: string;
};

export interface GeoJsonLayer {
  key: GeoJsonLayerKey;
  geoJson: any;
  color?: string;
  icon?: string;
  legend?: GeoJsonLayerLegend;
}

const SAR_ZONE_STYLES = {
  weight: 1,
  color: '#FD0808',
  stroke: true,
  opacity: 0.7,
  fillOpacity: 0.03,
};

const preprocessSarZones = (json: GeoJSON.FeatureCollection) => {
  const erroneousSarZones = [
    'Samsun (Turkish Coast Guard Black Sea Regional Command)',
  ];
  json.features = json.features
    .filter((feature) => !erroneousSarZones.includes(feature.properties?.name))
    .map((feature) => ({
      ...feature,
      properties: {
        ...feature.properties,
        style: {
          ...SAR_ZONE_STYLES,
        },
      },
    }));

  return json;
};

const preprocessContestedSarZones = (json: GeoJSON.FeatureCollection) => {
  json.features = json.features.map((feature) => ({
    ...feature,
    properties: {
      ...feature.properties,
      style: {
        ...SAR_ZONE_STYLES,
        dashArray: 10,
        weight: 3,
      },
    },
  }));
  return json;
};

export const geoJsonLayers: Array<GeoJsonLayer> = [
  {
    key: GeoJsonLayerKey['GSM Coverage'],
    color: '#005e91',
    geoJson: gsmCoverage,
    legend: {
      description: 'GSM Coverage',
      style: 'tw-border-solid tw-border-0 tw-border-t-[9px]',
    },
  },
  {
    key: GeoJsonLayerKey['Territorial Waters'],
    color: '#1b8f01',
    geoJson: territorialWaters,
    legend: {
      description: 'Territorial Waters',
      style: 'tw-border-solid tw-border-0 tw-border-t-[9px]',
    },
  },
  {
    key: GeoJsonLayerKey['SAR Zones'],
    color: '#FD0808',
    geoJson: preprocessSarZones(sarZones as GeoJSON.FeatureCollection),
    legend: {
      description: 'SAR Zones',
      style: 'tw-border-solid tw-border-0 tw-border-t-[3px]',
      groupByProperty: 'country',
    },
  },
  {
    key: GeoJsonLayerKey['Contested SAR Zones'],
    color: '#FD0808',
    geoJson: preprocessContestedSarZones(
      contestedSarZones as GeoJSON.FeatureCollection
    ),
    legend: {
      description: 'Contested SAR Zones',
      style: 'tw-border-dashed tw-border-0 tw-border-t-[3px]',
    },
  },
  {
    key: GeoJsonLayerKey['Oil and Gas Platforms'],
    geoJson: oilAndGasPlatforms,
    icon: 'mdi-tower-fire',
  },
];

export const geoJsonLayerKeys = geoJsonLayers.map((a) => a.key);
