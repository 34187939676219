
















































import Vue, { PropOptions } from 'vue';
import throttle from 'lodash.throttle';

export default Vue.extend({
  data() {
    return {
      hasVisibleTop: true,
      hasVisibleBottom: true,
    };
  },
  props: {
    contentPadding: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  methods: {
    keyEventHandler(event: KeyboardEvent): void {
      if (event.key === 'Escape') this.closeControlComponent();
    },
    closeControlComponent(): void {
      this.$accessor.ui.toggleSelectedControlComponent(undefined);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateScrollStatus: throttle(function (this: any): void {
      const content = this.$refs.content;
      if (!content) return;
      const contentHeight = content.scrollHeight - content.offsetHeight;
      this.hasVisibleTop = content.scrollTop <= 0;
      this.hasVisibleBottom = content.scrollTop >= contentHeight;
    }, 100),
  },
  mounted(): void {
    window.addEventListener('keyup', this.keyEventHandler);
    const observer = new ResizeObserver(() => {
      this.updateScrollStatus();
    });
    observer.observe(this.$refs.content as Element);
  },
  beforeDestroy(): void {
    window.removeEventListener('keyup', this.keyEventHandler);
  },
});
