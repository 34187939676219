export interface TileLayer {
  url: string;
  subdomains?: string;
  options?: L.TileLayerOptions;
}

export interface TileLayerGroup {
  name: string;
  tileLayers: TileLayer[];
}

const MAPBOX_TILESIZE = 512;

/**
 * Create a Mapbox tile layer for the given style id and using the credential from dotenv.
 *
 * @see {@link https://docs.mapbox.com/api/maps/static-tiles/}
 *
 * @param mapboxStyleId - The Mapbox style id.
 * @returns A Mapbox static tiles layer for usage with leaflet.
 */
const createMapboxTileLayer = (mapboxStyleId: string): TileLayer => {
  const draftModePart =
    process.env.VUE_APP_MAPBOX_IS_DRAFT_MODE === 'true' ? '/draft' : '';
  return {
    url: `https://api.mapbox.com/styles/v1/${process.env.VUE_APP_MAPBOX_USERNAME}/${mapboxStyleId}${draftModePart}/tiles/${MAPBOX_TILESIZE}/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`,
    options: {
      zoomOffset: -1,
      tileSize: MAPBOX_TILESIZE,
    },
  };
};

export const tileLayerGroups: TileLayerGroup[] = [
  {
    name: 'Mapbox Light',
    tileLayers: [createMapboxTileLayer('cl2qbpbtg007z14p0dcu9hg68')],
  },
  {
    name: 'Mapbox Dark',
    tileLayers: [createMapboxTileLayer('cl2pzkzv100gb14pk95vffwgh')],
  },
  {
    name: 'Mapbox Satellite',
    tileLayers: [createMapboxTileLayer('cl2pzb36v000h15l2711cvjfk')],
  },
];
