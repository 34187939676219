




















































import Vue, { PropOptions } from 'vue';
import {
  CoordinatesParserSuccess,
  DefaultFormatKey,
  coordinatesParserSuccessToString,
} from 'coordinate-formats';
import * as L from 'leaflet';
import { LCircleMarker, LTooltip } from 'vue2-leaflet';

export default Vue.extend({
  components: {
    LCircleMarker,
    LTooltip,
  },
  props: {
    label: {
      type: String,
      required: true,
    } as PropOptions<string>,
    color: {
      type: String,
      required: true,
    } as PropOptions<string>,
    possibleCoordinates: {
      type: Object,
      required: true,
    } as PropOptions<CoordinatesParserSuccess<DefaultFormatKey>>,
    positionId: {
      type: String,
      required: true,
    } as PropOptions<string>,
  },
  data() {
    return {
      isTooltipPermanent: false,
      tooltipRenderKey: new Date().valueOf(),
    };
  },
  computed: {
    coordinatesParserSuccessToString: () => coordinatesParserSuccessToString,
    tooltipOptions(): L.TooltipOptions {
      return {
        direction: 'top',
        permanent: this.isTooltipPermanent,
        interactive: true,
        opacity: 1,
        offset: L.point(0, -11),
      };
    },
    isSelectedPosition(): boolean {
      return this.positionId === this.$accessor.positions.selectedPositionId;
    },
  },
  watch: {
    label(): void {
      this.forceTooltipRerender();
    },
    possibleCoordinates(): void {
      this.forceTooltipRerender();
    },
  },
  methods: {
    onClick() {
      this.$accessor.positions.setSelectedPositionId(this.positionId);
      this.isTooltipPermanent = !this.isTooltipPermanent;
      this.forceTooltipRerender();
    },
    onEdit(): void {
      this.$accessor.positions.setSelectedPositionId(this.positionId);
      this.$accessor.ui.toggleSelectedControlComponent('PositionsEdit');
    },
    onClose(): void {
      this.isTooltipPermanent = false;
      this.forceTooltipRerender();
    },
    forceTooltipRerender(): void {
      this.tooltipRenderKey = new Date().valueOf();
    },
  },
});
