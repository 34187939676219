import {
  createPosition,
  createPositions,
  Position,
} from '@/lib/models/position';
import { getterTree, mutationTree, actionTree } from 'typed-vuex';

/**
 * The positions module's state.
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types*/
export const state = () => ({
  /**
   * The stored positions.
   */
  data: [] as Position[],

  /**
   * The selected position's id.
   */
  selectedPositionId: undefined as string | undefined,
});

/**
 * The positions module's getters.
 */
export const getters = getterTree(state, {
  /**
   * Get all positions.
   *
   * @returns All positions.
   */
  allPositions(state): Position[] {
    return createPositions(state.data);
  },

  selectedPosition(state): Position | undefined {
    const selectedPosition = state.data.find(
      (position) => position.id === state.selectedPositionId
    );
    return selectedPosition ? createPosition(selectedPosition) : undefined;
  },
});

/**
 * The key for storing positions to localStorage.
 */
export const POSITIONS_STORAGE_KEY = 'positions';

/**
 * The positions module's mutations.
 */
export const mutations = mutationTree(state, {
  /**
   * Set the positions.
   *
   * @param positions - The positions to set.
   */
  SET_POSITIONS(state, positions: Position[]): void {
    state.data = positions;
    localStorage.setItem(POSITIONS_STORAGE_KEY, JSON.stringify(state.data));
  },

  /**
   * Set the selected position id.
   *
   * @param positionId - The newly selected position's id.
   */
  SET_SELECTED_POSITION_ID(state, positionId: string | undefined): void {
    state.selectedPositionId = positionId;
  },
});

/**
 * The positions module's actions.
 */
export const actions = actionTree(
  { state, getters, mutations },
  {
    /**
     * Update/add a position.
     *
     * @param position - The position to update.
     */
    updatePositions({ commit, state }, position: Position): void {
      commit('SET_POSITIONS', [
        ...state.data.filter((x) => x.id !== position.id),
        position,
      ]);
    },

    /**
     * Set the positions.
     *
     * @param positions - The positions to set.
     */
    setPositions({ commit }, positions: Position[]): void {
      commit('SET_POSITIONS', positions);
    },

    /**
     * Remove a position.
     *
     * @param position - The position to remove.
     */
    removePosition({ commit, state }, position: Position): void {
      commit('SET_POSITIONS', [
        ...state.data.filter((x) => x.id !== position.id),
      ]);
    },

    /**
     * Clear the positions.
     */
    clearPositions({ commit }): void {
      commit('SET_POSITIONS', []);
      commit('SET_SELECTED_POSITION_ID', undefined);
    },

    /**
     * Set the selected position id.
     *
     * @param positionId - The id of the position to select.
     */
    setSelectedPositionId({ commit }, positionId: string | undefined): void {
      commit('SET_SELECTED_POSITION_ID', positionId);
    },
  }
);

/**
 * The positions module.
 */
export const positions = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
