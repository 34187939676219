











import Vue, { PropOptions, VueConstructor } from 'vue';
import * as L from 'leaflet';
import { LMarker, LTooltip, LIcon } from 'vue2-leaflet';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        marker: L.Marker;
      };
    }
  >
).extend({
  components: {
    LMarker,
    LTooltip,
    LIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    } as PropOptions<string>,
    latLon: {
      type: Array,
      required: true,
    } as PropOptions<number[]>,
    icon: {
      type: String,
      default: 'mdi-tower-fire',
    } as PropOptions<string>,
  },
  methods: {
    onClick(ev: L.LeafletMouseEvent) {
      ev.target.off('click');
    },
  },
});
