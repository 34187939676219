












import Vue from 'vue';

import { LTileLayer } from 'vue2-leaflet';

export default Vue.extend({
  components: {
    LTileLayer,
  },
});
