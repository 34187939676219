

















import Vue from 'vue';

import TheMenu from './components/TheMenu.vue';
import TheMap from './components/TheMap.vue';

export default Vue.extend({
  components: {
    TheMenu,
    TheMap,
  },
});
