export const boundsContainPoint = (
  bounds: GeoJSON.Position[],
  lat: number,
  lng: number
) => {
  //https://rosettacode.org/wiki/Ray-casting_algorithm
  const west = (
    A: GeoJSON.Position,
    B: GeoJSON.Position,
    lng: number,
    lat: number
  ): boolean => {
    if (A[1] <= B[1]) {
      if (lat <= A[1] || lat > B[1] || (lng >= A[0] && lng >= B[0])) {
        return false;
      } else if (lng < A[0] && lng < B[0]) {
        return true;
      } else {
        return (lat - A[1]) / (lng - A[0]) > (B[1] - A[1]) / (B[0] - A[0]);
      }
    } else {
      return west(B, A, lng, lat);
    }
  };

  let count = 0;
  for (let b = 0; b < bounds.length; b++) {
    const vertex1 = bounds[b];
    const vertex2 = bounds[(b + 1) % bounds.length];
    if (west(vertex1, vertex2, lng, lat)) ++count;
  }
  return count % 2 === 1;
};

export const getContainingFeatures = (
  geoJson: GeoJSON.FeatureCollection,
  latLng: [number, number]
) =>
  geoJson.features.filter((feature) => {
    let isWithin = false;
    let coordinates: Array<GeoJSON.Position> | undefined;
    switch (feature.geometry.type) {
      case 'MultiPolygon':
        isWithin = feature.geometry.coordinates.some((e) =>
          boundsContainPoint(e[0], latLng[0], latLng[1])
        );
        break;
      case 'GeometryCollection':
        coordinates = (
          feature.geometry.geometries.find(
            (a) => a.type === 'MultiPolygon'
          ) as GeoJSON.MultiPolygon
        ).coordinates[0][0];
        isWithin = boundsContainPoint(coordinates, latLng[0], latLng[1]);
        break;
      case 'Polygon':
        coordinates = feature.geometry.coordinates[0];
        isWithin = boundsContainPoint(coordinates, latLng[0], latLng[1]);
        break;
    }
    return isWithin;
  });
