








import Vue from 'vue';
import { DD, DMS } from 'coordinate-formats';

export default Vue.extend({
  computed: {
    formatted(): string {
      if (!this.$accessor.mapCursorPositionTuple) return '';
      return `${DD.latLonToString(
        this.$accessor.mapCursorPositionTuple
      )} | ${DMS.latLonToString(this.$accessor.mapCursorPositionTuple)}`;
    },
  },
});
