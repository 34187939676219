import Vue from 'vue';
import Vuex from 'vuex';
import { useAccessor, getterTree, mutationTree, actionTree } from 'typed-vuex';
import createPersistedState from 'vuex-persistedstate';
import { map } from './map';
import { positions } from './positions';
import { rccContacts } from './rccContacts';
import { ui } from './ui';

Vue.use(Vuex);

/**
 * The root state.
 */
const state = () => ({
  /**
   * Whether the menu is shown or not.
   */
  isShownMenu: undefined as boolean | undefined,

  /**
   * The map bounds.
   */
  bounds: null as L.LatLngBounds | null,
  
  /**
   * The map cursor's position.
   */
  mapCursorPosition: undefined as L.LatLng | undefined,
});

/**
 * The root getters.
 */
const getters = getterTree(state, {
  mapCursorPositionTuple(state): [number, number] | undefined {
    return state.mapCursorPosition
      ? [state.mapCursorPosition.lat, state.mapCursorPosition.lng]
      : undefined;
  },
});

/**
 * The root mutations.
 */
const mutations = mutationTree(state, {
  /**
   * Set whether the menu is shown or not.
   *
   * @param isShownMenu - Whether the menu is shown or not.
   */
  SET_IS_SHOWN_MENU(state, isShownMenu: boolean): void {
    state.isShownMenu = isShownMenu;
  },

  /**
   * Set the map bounds.
   *
   * @param bounds - The map bounds.
   */
  SET_BOUNDS(state, bounds: L.LatLngBounds): void {
    state.bounds = bounds;
  },

  /**
   * Set the map cursor's position.
   *
   * @param position - The map cursor's position.
   */
  SET_MAP_CURSOR_POSITION(state, position: L.LatLng): void {
    state.mapCursorPosition = position;
  },
});

/**
 * The root actions.
 */
const actions = actionTree(
  { state, getters, mutations },
  {
    /**
     * Toggle whether the menu is shown or not.
     *
     * @param isShownMenu Whether the menu is shown or not.
     */
    toggleIsShownMenu({ commit, state }, isShownMenu?: boolean): void {
      commit('SET_IS_SHOWN_MENU', isShownMenu ?? !state.isShownMenu);
    },

    /**
     * Set the map bounds.
     *
     * @param bounds - The map bounds.
     */
    setBounds({ commit }, bounds: L.LatLngBounds): void {
      commit('SET_BOUNDS', bounds);
    },

    /**
     * Set the map cursor's position.
     *
     * @param position - The map cursor's position.
     */
    setMapCursorPosition({ commit }, position: L.LatLng): void {
      commit('SET_MAP_CURSOR_POSITION', position);
    },
  }
);

/**
 * The root store pattern.
 */
const storePattern = {
  state,
  getters,
  mutations,
  actions,
  modules: {
    map,
    positions,
    rccContacts,
    ui,
  },
};

/**
 * The store API key.
 */
const VUEX_STORAGE_API_KEY = 'ap-shift-map';

/**
 * The store API version.
 */
const VUEX_STORAGE_API_VERSION = '4.0';

/**
 * The root store.
 */
export const store = new Vuex.Store({
  ...storePattern,
  plugins: [
    createPersistedState({
      key: `${VUEX_STORAGE_API_KEY}_${VUEX_STORAGE_API_VERSION}`,
      paths: ['map', 'positions'],
    }),
    createPersistedState({
      key: 'ui',
      paths: ['ui'],
      storage: sessionStorage,
    }),
  ],
});

/**
 * The root store accessor.
 */
export const accessor = useAccessor(store, storePattern);
