import { getterTree, mutationTree, actionTree } from 'typed-vuex';
import { tileLayerGroups, TileLayerGroup } from '@/data/tileLayerGroups';
import { GeoJsonLayerKey } from '@/data/geoJsonLayers';

/**
 * The map module's state.
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types*/
export const state = () => ({
  /**
   * The map zoom level.
   */
  zoom: 4,

  /**
   * The map center.
   */
  center: [32, 15] as L.LatLngExpression,

  /**
   * The selected tile layer group.
   */
  selectedTileLayerGroup: tileLayerGroups[0],

  /**
   * The selected GeoJSON layer keys.
   */
  selectedGeoJsonLayerKeys: [
    GeoJsonLayerKey['SAR Zones'],
    GeoJsonLayerKey['Contested SAR Zones'],
    GeoJsonLayerKey['Territorial Waters'],
  ],
});

/**
 * The map module's getters.
 */
export const getters = getterTree(state, {});

/**
 * The map module's mutations.
 */
export const mutations = mutationTree(state, {
  /**
   * Set the map zoom level.
   *
   * @param zoom - The map zoom level.
   */
  SET_ZOOM(state, zoom: number): void {
    state.zoom = zoom;
  },

  /**
   * Set the map center.
   *
   * @param center - The map center.
   */
  SET_CENTER(state, center: L.LatLngExpression): void {
    state.center = center;
  },

  /**
   * Set the selected tile layer group.
   *
   * @param selectedTileLayerGroup - The selected tile layer group.
   */
  SET_SELECTED_TILE_LAYER_GROUP(
    state,
    selectedTileLayerGroup: TileLayerGroup
  ): void {
    state.selectedTileLayerGroup = selectedTileLayerGroup;
  },

  /**
   * Set the selected GeoJSON layer keys.
   *
   * @param selectedGeoJsonLayerKeys - The selected GeoJSON layer keys.
   */
  SET_SELECTED_GEO_JSON_LAYER_KEYS(
    state,
    selectedGeoJsonLayerKeys: GeoJsonLayerKey[]
  ): void {
    state.selectedGeoJsonLayerKeys = selectedGeoJsonLayerKeys;
  },
});

/**
 * The map module's actions.
 */
export const actions = actionTree(
  { state, getters, mutations },
  {
    /**
     * Set the map zoom level.
     *
     * @param zoom - The map zoom level.
     */
    setZoom({ commit }, zoom: number): void {
      commit('SET_ZOOM', zoom);
    },

    /**
     * Set the map center.
     *
     * @param center - The map center.
     */
    setCenter({ commit }, center: L.LatLngExpression): void {
      commit('SET_CENTER', center);
    },

    /**
     * Set the selected tile layer group.
     *
     * @param selectedTileLayerGroup - The selected tile layer group.
     */
    setSelectedTileLayerGroup(
      { commit },
      selectedTileLayerGroup: TileLayerGroup
    ) {
      commit('SET_SELECTED_TILE_LAYER_GROUP', selectedTileLayerGroup);
    },

    /**
     * Set the selected tile layer group by passing a group name.
     *
     * @param selectedTileLayerGroup - The selected tile layer group.
     */
    setSelectedTileLayerGroupByName(
      { commit },
      selectedTileLayerGroupName: string
    ) {
      const selectedTileLayerGroup = tileLayerGroups.find(
        (group) => group.name === selectedTileLayerGroupName
      );
      if (selectedTileLayerGroup) {
        commit('SET_SELECTED_TILE_LAYER_GROUP', selectedTileLayerGroup);
      }
    },

    /**
     * Set the selected GeoJSON layer keys.
     *
     * @param selectedGeoJsonLayerKeys - The selected GeoJSON layer keys.
     */
    setSelectedGeoJsonLayerKeys(
      { commit },
      selectedGeoJsonLayerKeys: GeoJsonLayerKey[]
    ) {
      commit('SET_SELECTED_GEO_JSON_LAYER_KEYS', selectedGeoJsonLayerKeys);
    },
  }
);

/**
 * The map module.
 */
export const map = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
