import { getterTree, mutationTree, actionTree } from 'typed-vuex';

/**
 * The RCC contacts module's state.
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types*/
export const state = () => ({
  /**
   * The selected rcc.
   */
  selectedRccName: undefined as string | undefined,
});

/**
 * The RCC contacts module's getters.
 */
export const getters = getterTree(state, {
  selectedRccName(state): string | undefined {
    return state.selectedRccName;
  },
});

/**
 * The RCC contacts module's mutations.
 */
export const mutations = mutationTree(state, {
  /**
   * Set the RCC contacts.
   *
   * @param RCC contacts - The RCC contacts to set.
   */
  SET_SELECTED_RCC_NAME(state, selectedRccName: string | undefined): void {
    state.selectedRccName = selectedRccName;
  },
});

/**
 * The RCC contacts module's actions.
 */
export const actions = actionTree(
  { state, getters, mutations },
  {
    /**
     * Set the selected RCC.
     *
     * @param rccName - The name of the RCC to select.
     */
    setSelectedRcc({ commit }, rccName: string | undefined): void {
      commit('SET_SELECTED_RCC_NAME', rccName);
    },
  }
);

/**
 * The RCC contacts module.
 */
export const rccContacts = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
