import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import { accessor, store } from './store';

import './main.css';
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false;

Vue.prototype.$accessor = accessor;

new Vue({
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
