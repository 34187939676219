/**
 * Create a looping iterator function for the given array.
 * @param arr - The array to iterate through.
 * @returns A looping iterator function for the given array.
 */
export function* makeLoopingArrayIterator<T>(
  arr: T[]
): Generator<T, T, unknown> {
  let index = 0;
  while (index < Infinity) {
    yield arr[index];
    if (index === arr.length - 1) {
      index = 0;
    } else {
      index = index + 1;
    }
  }
  return arr[index];
}
