































import { GeoJsonLayer } from '@/data/geoJsonLayers';
import { getContainingFeatures } from '@/lib/map';
import Vue, { PropOptions } from 'vue';

export default Vue.extend({
  name: 'GeoJsonLayerLegend',
  props: {
    layer: {
      type: Object,
      required: true,
    } as PropOptions<GeoJsonLayer>,
  },
  computed: {
    containingLayers(): ReturnType<typeof getContainingFeatures> {
      return this.$accessor.mapCursorPositionTuple
        ? getContainingFeatures(
            this.layer.geoJson,
            this.$accessor.mapCursorPositionTuple
          )
        : [];
    },
    groupedLayers(): Record<string, ReturnType<typeof getContainingFeatures>> {
      const groupBy = this.layer.legend?.groupByProperty ?? 'key';
      return this.containingLayers.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.properties?.[groupBy]]: [
            ...(acc?.[cur.properties?.[groupBy]] ?? []),
            cur,
          ],
        }),
        {} as Record<string, ReturnType<typeof getContainingFeatures>>
      );
    },
    hasContainingLayers(): boolean {
      return this.containingLayers.length > 0;
    },
  },
});
