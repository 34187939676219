

























import { TileLayerGroup, tileLayerGroups } from '@/data/tileLayerGroups';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    tileLayerGroups() {
      return tileLayerGroups;
    },
    selectedTileLayerGroup: {
      get(): TileLayerGroup {
        return this.$accessor.map.selectedTileLayerGroup;
      },
      set(selectedTileLayerGroup: TileLayerGroup): void {
        this.$accessor.map.setSelectedTileLayerGroup(selectedTileLayerGroup);
      },
    },
  },
});
