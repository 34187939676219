import { CoordinatesParserSuccess, DefaultFormatKey } from 'coordinate-formats';
import { makeLoopingArrayIterator } from '../iterators';

export const positionMarkerColors = [
  '#003EFF',
  '#26A400',
  '#FF8F00',
  '#FF0000',
  '#A700FF',
  '#FDFF00',
];

export const positionMarkerColorsIterator =
  makeLoopingArrayIterator(positionMarkerColors);

export interface PositionSpec {
  id: string;
  coordinatesString: string;
  selectedPossibleCoordinates: Array<
    CoordinatesParserSuccess<DefaultFormatKey>
  >;
  label: string;
  color: string;
  createdAt: string;
  updatedAt: string;
}

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types*/
export function createPosition(spec: PositionSpec) {
  return {
    ...spec,
    createdAtString: new Date(spec.createdAt).toLocaleString(),
    updatedAtString: new Date(spec.updatedAt).toLocaleString(),
    selectedPossibleCoordinates: spec.selectedPossibleCoordinates,
  };
}

export type Position = ReturnType<typeof createPosition>;

export function createPositions(specs: PositionSpec[]): Position[] {
  return specs.map((x) => createPosition(x));
}

export interface PositionsJson {
  exportedAt: string;
  positions: Position[];
}

export function createPositionsJsonDownloadData(
  positionsJson: PositionsJson
): PositionsJsonDownloadData {
  const serialized = JSON.stringify(positionsJson);
  return {
    filename: `positions_${positionsJson.exportedAt}`,
    dataUrl: `data:,${encodeURIComponent(serialized)}`,
  };
}

export type PositionsJsonDownloadData = {
  filename: string;
  dataUrl: string;
};

/**
 * Parse a serialized positions JSON file.
 *
 * @param positionsJsonSerialized - The serialized positions JSON file.
 * @returns The parsed positions.
 */
export function parsePositionsJson(
  positionsJsonSerialized: string
): Position[] {
  const positionsJson: PositionsJson = JSON.parse(positionsJsonSerialized);
  return createPositions(positionsJson.positions);
}
