




























import Vue, { VueConstructor } from 'vue';
import * as L from 'leaflet';
import { LMap, LControl } from 'vue2-leaflet';

delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import PositionsMarkers from '@/components/PositionsMarkers.vue';
import ControlComponentWrapper from '@/components/ControlComponentWrapper.vue';
import GeoJsonLayers from '@/components/GeoJsonLayers.vue';
import TileLayers from '@/components/TileLayers.vue';
import ZoomButtons from '@/components/ZoomButtons.vue';
import MenuToggle from '@/components/MenuToggle.vue';
import CursorCoordinates from '@/components/CursorCoordinates.vue';
import { Position } from '@/lib/models/position';
import { CoordinatesParserSuccess, DefaultFormatKey } from 'coordinate-formats';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        leafletMap: {
          mapObject: L.Map;
        };
      };
    }
  >
).extend({
  components: {
    LMap,
    LControl,
    PositionsMarkers,
    ControlComponentWrapper,
    GeoJsonLayers,
    TileLayers,
    ZoomButtons,
    MenuToggle,
    CursorCoordinates,
  },
  data() {
    return {
      leafletMap: undefined as L.Map | undefined,
    };
  },
  watch: {
    selectedPosition(selectedPosition: Position | undefined): void {
      if (selectedPosition) {
        this.fitBoundsToPositions(selectedPosition);
      }
    },
  },
  computed: {
    zoom: {
      get(): number {
        return this.$accessor.map.zoom;
      },
      set(zoom: number): void {
        this.$accessor.map.setZoom(zoom);
      },
    },
    center: {
      get(): L.LatLngExpression {
        return this.$accessor.map.center;
      },
      set(center: L.LatLngExpression): void {
        this.$accessor.map.setCenter(center);
      },
    },
    bounds: {
      get(): L.LatLngBounds {
        return this.$accessor.bounds;
      },
      set(bounds: L.LatLngBounds): void {
        this.$accessor.setBounds(bounds);
      },
    },
    selectedPosition(): Position | undefined {
      return this.$accessor.positions.selectedPosition;
    },
  },
  mounted(): void {
    this.$nextTick(() => {
      this.leafletMap?.on('click', () => {
        if (this.$accessor.ui.selectedControlComponent !== 'MeasureDistance') {
          this.$accessor.ui.clearSelectedControlComponent();
        }
      });
      this.leafletMap?.addEventListener(
        'mousemove',
        (ev: L.LeafletMouseEvent) => {
          this.$accessor.setMapCursorPosition(ev.latlng);
        }
      );
    });
  },
  methods: {
    fitBoundsToPositions(...positions: Position[]): void {
      const possibleCoordinates: Array<
        CoordinatesParserSuccess<DefaultFormatKey>
      > = positions.flatMap((position) => position.selectedPossibleCoordinates);
      if (this.leafletMap) {
        const possibleCoordinatesLatLonTuples = possibleCoordinates.map(
          (coordinates) => coordinates.latLon
        );
        const boundsZoom = this.leafletMap.getBoundsZoom(
          possibleCoordinatesLatLonTuples
        );
        this.leafletMap.fitBounds(possibleCoordinatesLatLonTuples, {
          padding: [30, 30],
          maxZoom: boundsZoom < this.zoom ? boundsZoom : this.zoom,
        });
      }
    },
  },
});
