import { AsyncComponent } from 'vue';
import ControlComponentLoadingSkeleton from '@/components/ControlComponentLoadingSkeleton.vue';
import { component } from 'vue/types/umd';

/**
 * All the UI control component imports.
 */
export const controlComponents: Record<string, AsyncComponent> = {
  MeasureDistance: () => ({
    component: import('@/components/MeasureDistance.vue'),
    loading: ControlComponentLoadingSkeleton,
  }),
  PositionsDownload: () => ({
    component: import('@/components/PositionsDownload.vue'),
    loading: ControlComponentLoadingSkeleton,
  }),
  PositionsAdd: () => ({
    component: import('@/components/PositionsAdd.vue'),
    loading: ControlComponentLoadingSkeleton,
  }),
  PositionsEdit: () => ({
    component: import('@/components/PositionsEdit.vue'),
    loading: ControlComponentLoadingSkeleton,
  }),
  PositionsIndex: () => ({
    component: import('@/components/PositionsIndex.vue'),
    loading: ControlComponentLoadingSkeleton,
  }),
  PositionsMarkers: () => ({
    component: import('@/components/PositionsMarkers.vue'),
    loading: ControlComponentLoadingSkeleton,
  }),
  PositionsRestore: () => ({
    component: import('@/components/PositionsRestore.vue'),
    loading: ControlComponentLoadingSkeleton,
  }),
  RccContacts: () => ({
    component: import('@/components/RccContacts.vue'),
    loading: ControlComponentLoadingSkeleton,
  }),
};

/**
 * The possible names of a control component.
 */
export type ControlComponentName = keyof typeof controlComponents;
