











import Vue from 'vue';
import ControlComponent from './ControlComponent.vue';

export default Vue.extend({
  components: { ControlComponent },
});
