







import Vue from 'vue';
export default Vue.extend({
  methods: {
    toggleIsShownMenu(): void {
      this.$accessor.toggleIsShownMenu();
    },
  },
});
