import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"tw-mt-2 tw-w-[calc(100vw-20px)] sm:tw-max-w-[450px] tw-flex tw-flex-col tw-max-h-[calc(100vh-110px)]"},[(_vm.$slots.header)?[_c(VCardTitle,{staticClass:"tw-flex-nowrap tw-items-start"},[_c('div',{staticClass:"tw-grow tw-mr-2 tw-leading-tight"},[_vm._t("header")],2),_c(VBtn,{staticClass:"ml-auto -tw-my-2 -tw-mr-2",attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.closeControlComponent()}}},[_c(VIcon,{staticClass:"p-0"},[_vm._v("mdi-close")])],1)],1)]:_vm._e(),(_vm.$slots.content)?[(!_vm.hasVisibleTop)?_c(VDivider):_vm._e(),_c(VCardText,{ref:"content",staticClass:"tw-relative tw-overflow-y-auto tw-max-h-full",class:{
        '!tw-pt-1': _vm.$slots.header,
        '!tw-pb-1': _vm.$slots.footer,
        'tw-p-0': _vm.contentPadding === false,
      },on:{"scroll":_vm.updateScrollStatus}},[_vm._t("content")],2),(!_vm.hasVisibleBottom)?_c(VDivider):_vm._e()]:_vm._e(),(_vm.$slots.footer)?[_c(VSheet,{staticClass:"tw-p-4 tw-flex tw-flex-row-reverse tw-gap-x-4",class:{ 'tw-pt-0': _vm.hasVisibleTop && _vm.hasVisibleBottom }},[_vm._t("footer")],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }