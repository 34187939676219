import { render, staticRenderFns } from "./GeoJsonLayerLegend.vue?vue&type=template&id=5b274509&"
import script from "./GeoJsonLayerLegend.vue?vue&type=script&lang=ts&"
export * from "./GeoJsonLayerLegend.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports