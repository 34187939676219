




































































import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

import TileLayerSelection from '@/components/TileLayerSelection.vue';
import GeoJsonLayerSelection from '@/components/GeoJsonLayerSelection.vue';
import { ControlComponentName } from '@/lib/controlComponents';

interface ToolbarItem {
  icon: string;
  component: ControlComponentName;
  title: TranslateResult;
  onClick?: () => void;
}

export default Vue.extend({
  components: {
    TileLayerSelection,
    GeoJsonLayerSelection,
  },
  computed: {
    isShownMenu: {
      get(): boolean | undefined {
        return this.$accessor.isShownMenu;
      },
      set(isShownMenu: boolean): void {
        this.$accessor.toggleIsShownMenu(isShownMenu);
      },
    },
    isMini(): boolean {
      return (
        this.$vuetify.breakpoint.mdAndUp &&
        this.$vuetify.breakpoint.width <= 1460
      );
    },
    selectedControlComponentName: {
      get(): ControlComponentName | undefined {
        return this.$accessor.ui.selectedControlComponent;
      },
      set(controlComponentName: ControlComponentName): void {
        this.$accessor.ui.toggleSelectedControlComponent(controlComponentName);
      },
    },
    isDarkMode: {
      get(): boolean {
        return this.$accessor.ui.isDarkMode;
      },
      set(value: boolean): void {
        this.$accessor.ui.setIsDarkMode(value);
      },
    },
    toolbars(): ToolbarItem[][] {
      return [
        [
          {
            title: this.$t('Add position'),
            component: 'PositionsAdd',
            onClick: () => {
              this.$accessor.positions.setSelectedPositionId(undefined);
            },
            icon: 'mdi-map-marker-plus',
          },
          {
            title: this.$t('Show positions list'),
            component: 'PositionsIndex',
            icon: 'mdi-view-list',
          },
        ],
        [
          {
            title: this.$t('RCC Contacts'),
            component: 'RccContacts',
            icon: 'mdi-contacts',
          },
        ],
        [
          {
            title: this.$t('Measure distance'),
            component: 'MeasureDistance',
            icon: 'mdi-ruler',
          },
        ],
        [
          {
            title: this.$t('Download positions'),
            component: 'PositionsDownload',
            icon: 'mdi-download',
          },
          {
            title: this.$t('Restore positions'),
            component: 'PositionsRestore',
            icon: 'mdi-restore',
          },
        ],
      ];
    },
  },
  watch: {
    isDarkMode: {
      handler(value: boolean): void {
        this.$vuetify.theme.dark = value;
        if (
          ['Mapbox Dark', 'Mapbox Light'].includes(
            this.$accessor.map.selectedTileLayerGroup.name
          )
        ) {
          this.$accessor.map.setSelectedTileLayerGroupByName(
            value === true ? 'Mapbox Dark' : 'Mapbox Light'
          );
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        this.isDarkMode = e.matches;
      });
  },
});
