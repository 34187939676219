import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"tw-z-[9999]",attrs:{"app":"","expand-on-hover":_vm.isMini,"mini-variant":_vm.isMini},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"tw-p-2.5"},[_c(VBtn,{attrs:{"icon":"","title":_vm.isDarkMode ? _vm.$t('Switch to light mode') : _vm.$t('Switch to dark mode')},on:{"click":function($event){_vm.isDarkMode = !_vm.isDarkMode}}},[_c(VIcon,[_vm._v(_vm._s(_vm.isDarkMode ? 'mdi-white-balance-sunny' : 'mdi-weather-night'))])],1)],1)]},proxy:true}]),model:{value:(_vm.isShownMenu),callback:function ($$v) {_vm.isShownMenu=$$v},expression:"isShownMenu"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c('tile-layer-selection'),_c('geo-json-layer-selection')],1),_c(VDivider),_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{model:{value:(_vm.selectedControlComponentName),callback:function ($$v) {_vm.selectedControlComponentName=$$v},expression:"selectedControlComponentName"}},[_vm._l((_vm.toolbars),function(toolbar,index){return [(index > 0)?_c(VDivider,{key:("toolbar-divider-" + index),staticClass:"my-2"}):_vm._e(),_vm._l((toolbar),function(item){return _c(VListItem,{key:item.title,class:{
            'primary--text': _vm.selectedControlComponentName === item.component,
          },attrs:{"value":item.component},on:{"click":function () {
              if (item.onClick) { item.onClick(); }
              if (_vm.$vuetify.breakpoint.mobile) {
                _vm.isShownMenu = false;
              }
            }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }