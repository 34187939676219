import { ControlComponentName } from '@/lib/controlComponents';
import { getterTree, mutationTree, actionTree } from 'typed-vuex';
import { rccContacts } from './rccContacts';

/**
 * The map module's state.
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types*/
export const state = () => ({
  /**
   * The selected control component.
   */
  selectedControlComponent: undefined as ControlComponentName | undefined,
  isDarkMode:
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches,
});

/**
 * The map module's getters.
 */
export const getters = getterTree(state, {});

/**
 * The map module's mutations.
 */
export const mutations = mutationTree(state, {
  /**
   * Set the selected control component.
   *
   * @param selectedControlComponent - The the selected control component.
   */
  SET_SELECTED_CONTROL_COMPONENT(
    state,
    selectedControlComponent: ControlComponentName | undefined
  ): void {
    state.selectedControlComponent = selectedControlComponent;
  },

  /**
   * Set the app dark mode status.
   *
   * @param value - Whether the dark mode is active or not.
   */
  SET_IS_DARK_MODE(state, value: boolean): void {
    state.isDarkMode = value;
  },
});

/**
 * The map module's actions.
 */
export const actions = actionTree(
  { state, getters, mutations },
  {
    /**
     * Toggle the selected control component.
     *
     * @param selectedControlComponent - The selected control component.
     */
    toggleSelectedControlComponent(
      { commit, state },
      selectedControlComponent: ControlComponentName | undefined
    ): void {
      commit(
        'SET_SELECTED_CONTROL_COMPONENT',
        state.selectedControlComponent !== selectedControlComponent
          ? selectedControlComponent
          : undefined
      );
    },

    /**
     * Clear the selected control component state, so that none is show.
     */
    clearSelectedControlComponent({ commit }): void {
      commit('SET_SELECTED_CONTROL_COMPONENT', undefined);
    },

    /**
     * Set the dark mode to true or false
     *
     * @param value - Whether the dark mode is active or not.
     */
    setIsDarkMode({ commit }, value: boolean): void {
      commit('SET_IS_DARK_MODE', value);
    },
  }
);

/**
 * The UI module.
 */
export const ui = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
