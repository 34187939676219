









































import Vue from 'vue';

import { LCircleMarker, LPolyline, LTooltip } from 'vue2-leaflet';
import * as L from 'leaflet';

type CoordinateGridLine = {
  label: string;
  latLngs: Array<Array<number>>;
};

export default Vue.extend({
  components: {
    LCircleMarker,
    LPolyline,
    LTooltip,
  },
  computed: {
    lineInterval(): number {
      const zoom = this.$accessor.map.zoom;
      if (zoom <= 2) {
        return 40;
      } else if (zoom <= 3) {
        return 20;
      } else if (zoom <= 4) {
        return 10;
      } else if (zoom <= 5) {
        return 5;
      } else {
        return 1;
      }
    },
    coordinateSteps(): (
      lowerBound: number,
      upperBound: number
    ) => Array<number> {
      return (lowerBound: number, upperBound: number): Array<number> => {
        lowerBound = Math.floor(lowerBound);
        upperBound = Math.ceil(upperBound);
        const start = lowerBound - (lowerBound % this.lineInterval);
        const end = upperBound - (upperBound % this.lineInterval);
        return Array.from({
          length: (end - start) / this.lineInterval + 1,
        }).map((_a, i) => i * this.lineInterval + start);
      };
    },
    latitudes(): Array<CoordinateGridLine> {
      const steps = this.coordinateSteps(this.southBound, this.northBound);
      return steps.map((n: number) => {
        let label;
        if (n < 0) {
          label = `${Math.abs(n)}° S`;
        } else if (n === 0) {
          label = `${Math.abs(n)}°`;
        } else {
          label = `${Math.abs(n)}° N`;
        }
        return {
          label,
          latLngs: [
            [n, -180],
            [n, 180],
          ],
        };
      });
    },
    longitudes(): Array<CoordinateGridLine> {
      const steps = this.coordinateSteps(this.westBound, this.eastBound);
      return steps.map((n: number) => {
        let label;
        if (n < 0) {
          label = `${Math.abs(n)}° W`;
        } else if (n === 0) {
          label = `${Math.abs(n)}°`;
        } else {
          label = `${Math.abs(n)}° E`;
        }
        return {
          label,
          latLngs: [
            [-90, n],
            [90, n],
          ],
        };
      });
    },
    latTooltipOptions(): L.TooltipOptions {
      return {
        direction: 'top',
        permanent: true,
        opacity: 1,
        className: 'labelTooltip',
        offset: [16, 0],
      };
    },
    lngTooltipOptions(): L.TooltipOptions {
      return {
        direction: 'top',
        permanent: true,
        opacity: 1,
        className: 'labelTooltip',
        offset: [16, 20],
      };
    },
    mapBounds(): L.LatLngBounds | null {
      return this.$accessor.bounds;
    },
    northBound(): number {
      const max = 90;
      return Math.min(this.mapBounds?.getNorth() || max, max);
    },
    southBound(): number {
      const min = -90;
      return Math.max(this.mapBounds?.getSouth() || min, min);
    },
    eastBound(): number {
      const max = 180;
      return Math.min(this.mapBounds?.getEast() || max, max);
    },
    westBound(): number {
      const min = -180;
      return Math.max(this.mapBounds?.getWest() || min, min);
    },
  },
});
