




























import Vue from 'vue';
import { GeoJsonLayerKey, geoJsonLayerKeys } from '@/data/geoJsonLayers';

export default Vue.extend({
  computed: {
    selectedGeoJsonLayerKeys: {
      get(): GeoJsonLayerKey[] {
        return this.$accessor.map.selectedGeoJsonLayerKeys;
      },
      set(selectedGeoJsonLayerKeys: GeoJsonLayerKey[]): void {
        this.$accessor.map.setSelectedGeoJsonLayerKeys(
          selectedGeoJsonLayerKeys
        );
      },
    },
    geoJsonLayerKeys() {
      return geoJsonLayerKeys;
    },
  },
});
